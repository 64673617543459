import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
import Swal from 'sweetalert2';
import { passwordComplexityValidator } from 'src/app/shared/validators/password-complexity.validator';
import { ClientsService } from 'src/app/shared/services/user-management/clients.service';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { FieldService } from 'src/app/shared/services/data/field.service';
import { AreaService } from 'src/app/shared/services/user-management/area.service';
import { Output, EventEmitter, Input } from '@angular/core';
import { DemandsService } from '../../services/demands/demands.service';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss']
})
export class FieldsComponent implements OnInit {
  areas: any = null;
  @Input() area_id: any;
  @Input() context: string = null;
  @Input() type = 'demand';
  @Input() clickSubmit = false;
  @Input() showArea = true;
  @Input() columnsPerLine:any = 1;
  @Input() saveFieldsOnSelect = false;
  @Input() model_id:number;
  @Output() changeArea = new EventEmitter();
  @Output() allFieldsFilled = new EventEmitter();

  currentUser: any;
  private _fields: any;

  @Input() 
  set fields(value: any) {
    this._fields = value;
    if (value) {
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log('FIELDS')
      console.log(this.fields);
      this.fields.forEach(field => {
        this.updateFilteredChildValues(field);
      });
      this.handleFieldsChange();
    }
  }

  get fields(): any {
    return this._fields;
  }

  @Input() styles = { 
    labelColor: '#4a4a4a',
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    this.getAreas();
  }

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private fieldService: FieldService,
    private areaService: AreaService,
    private auth: AuthService,
    private demandService: DemandsService,
    private clientsService: ClientsService
  ) { }

  getColumnClass(columns: string): string {
    switch (columns) {
      case '1':
        return 'col-12';
      case '2':
        return 'col-6';
      case '3':
        return 'col-4';
      case '4':
        return 'col-3';
      case '6':
        return 'col-2';
      default:
        return 'col-12'; // Padrão
    }
  }

  handleFieldsChange(): void {
    this.checkIfFieldsAreFilled();
  }

  checkIfFieldsAreFilled(): boolean {
    const validateField = (field: any): boolean => {
      // Verifica se o campo é preenchido
      field.filled = !field.required || Boolean(field.selecteds || field.selecteds?.length);
  
      // Valida recursivamente os filhos visíveis
      if (field.children?.length) {
        field.children
          .filter((child: any) => this.shouldDisplayChild(child, field))
          .forEach(validateField);
      }
  
      return field.filled;
    };
  
    // Verifica todos os campos obrigatórios e visíveis
    const allFieldsValid = this.fields
      .filter(field => !field.area_id || field.area_id === this.area_id)
      .every(validateField);

    // console.log(allFieldsValid);

    this.allFieldsFilled.emit(allFieldsValid);
    return allFieldsValid;
  }
  

  getAreas(): void {
    this.areas = this.areaService.getAll().subscribe({
      next: (result) => {
        this.areas = result;
      },
      error: (err) => console.log(err)
    });
  }

  handleArea(area): void {
    this.fields.forEach((field: any) => {
      if (field.area_id) {
        this.removeSelecteds(field);
      }
    });
    this.changeArea.emit(this.area_id);
    this.checkIfFieldsAreFilled();
  }

  onChange(field: any, optionId: any, event: any): void {
    const isChecked = event.target.checked;

    if (field.multiple) {
      if (isChecked) {
        field.selecteds.push(optionId);
      } else {
        const index = field.selecteds.indexOf(optionId);
        if (index > -1) {
          field.selecteds.splice(index, 1);
        }
      }
    } else {
      field.selecteds = [optionId];
    }

    this.removeSelecteds(field);
    this.updateFilteredChildValues(field);
  }

  shouldDisplayChild(child: any, field: any): boolean {
    return this.canDisplay(child, field);
  }

  canDisplay(child: any, field: any) { 
    if (field.multiple) {
      let canDisplay = (field.selecteds.includes(child.depends_on_value_id) || child.parent_id === field.id) && this.hasPermission(field);
      return canDisplay;
    } else {
      let canDisplay = (
        (child.depends_on_value_id.length >= 0 && child.depends_on_value_id.includes(field.selecteds)) ||
        (child.parent_id === field.id && child.depends_on_value_id.length === 0 && field.user_group_permissions == null)
      ) && this.hasPermission(field);

      return canDisplay;
    }
  }

  hasPermission(field) {
    if (field.user_group_permissions == null) {
      return true;
    } else if (
      field.user_group_permissions &&
      field.user_group_permissions.context.includes(this.context) &&
      field.user_group_permissions.can_view
    ) { 
      return true;
    } else { 
      return false;
    }
  }

  removeSelecteds(field: any): void {
    if (field.children && Array.isArray(field.children)) {
      field.children.forEach((child: any) => {
        // Verifica se "depends_on_value_id" é um array não vazio
        if (Array.isArray(child.depends_on_value_id) && child.depends_on_value_id.length > 0) {
          child.selecteds = []; // Limpa os valores selecionados do filho
        }
        // Continua a limpar os filhos recursivamente
        this.removeSelecteds(child);
      });
    }
  }

  onSelectFieldValueChange(field: any, event: any): void {
    console.log('change');
    console.log(field);
    console.log(event);
    this.removeSelecteds(field);
    this.updateFilteredChildValues(field);

    this.demandService
    .syncModelFieldValues(this.model_id, 'demand', {
      selected_values: event,
      field_id: field.id
    })
    .subscribe({
      next: (result) => console.log(result),
      error: (err) => console.log(err)
    });
  }

  updateFilteredChildValues(field: any): void {
    if (field.children) {
      field.children.forEach((child: any) => {
        const selectedValue = Array.isArray(field.selecteds) ? field.selecteds : [field.selecteds];
        child.filteredValues = child.values.filter(value => {
          return !value.depends_on_parent_value_id || selectedValue.includes(value.depends_on_parent_value_id);
        });

        // Processa os filhos recursivamente
        if (child.children) {
          this.updateFilteredChildValues(child);
        }
      });
    }

    this.checkIfFieldsAreFilled();
  }


}
