import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends BaseService {

   // Método para exportar as demandas (arquivo Excel)
   exportDemands(start: string, deadline: string, area_id: number): Observable<Blob> {
    const filters = { start, deadline, area_id: area_id };
    return this.downloadFile('exports/demands', filters);
  }

  // Método para exportar as demandas (arquivo Excel)
  exportDemandsTasks(start: string, deadline: string, area_id: number): Observable<Blob> {
    const filters = { start, deadline, area_id: area_id };
    return this.downloadFile('exports/demands/tasks', filters);
  }


}
