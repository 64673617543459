import { Component, Input } from '@angular/core';
import { DemandsService } from '../../services/demands/demands.service';
import { UserService } from '../../services/user-management/user.service';

@Component({
  selector: 'app-field-recursive',
  templateUrl: './field-recursive.component.html',
  styleUrls: ['./field-recursive.component.scss']
})
export class FieldRecursiveComponent {
  @Input() demand: any = null;
  @Input() disableFields: boolean = false;
  @Input() fields: any[] = []; // Agora é um array de fields
  @Input() area_id: any;
  @Input() fieldSize = '33';

  currentUser: any;

  constructor(
    private demandService: DemandsService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.currentUser = this.userService.getLocalUser();
    this.fields.forEach((field) => this.filterChildValues(field));
  }

  isDisabled(field: any): boolean {
    return (
      this.disableFields ||
      (field.user_group_permissions &&
        !field.user_group_permissions.id === this.currentUser.group.id &&
        !field.user_group_permissions.can_edit)
    );
  }

  onItemSelect(field: any, fieldValue: any) {
    console.log(field);
    console.log(fieldValue);
    this.removeSelecteds(field);
    this.filterChildValues(field);

    this.demandService
      .saveModelFieldValue(this.demand.id, 'demand', {
        field_value_id: fieldValue.id,
        field_id: field.id
      })
      .subscribe({
        next: (result) => console.log(result),
        error: (err) => console.log(err)
      });
  }

  onItemDeSelect(field: any, fieldValue: any) {
    this.removeSelecteds(field);
    this.filterChildValues(field);

    this.demandService
      .deleteModelFieldValue(this.demand.id, 'demand', {
        field_value_id: fieldValue.id,
        field_id: field.id
      })
      .subscribe({
        next: (result) => console.log(result),
        error: (err) => console.log(err)
      });
  }

  removeSelecteds(field: any): void {
    if (field.children && Array.isArray(field.children)) {
      field.children.forEach((child: any) => {
        // Verifica se "depends_on_value_id" é um array não vazio
        if (Array.isArray(child.depends_on_value_id) && child.depends_on_value_id.length > 0) {
          child.selecteds = []; // Limpa os valores selecionados do filho
        }
        // Continua a limpar os filhos recursivamente
        this.removeSelecteds(child);
      });
    }
  }

  filterChildValues(field: any): void {
    if (field.children && Array.isArray(field.children)) {
      field.children.forEach((child: any) => {
        if (Array.isArray(child.originalValues)) { // Verifica se é um array
          if (Array.isArray(field.selecteds) && field.selecteds.length > 0) { // Verifica se selecteds é um array válido
            child.values = child.originalValues.filter((value: any) =>
              field.selecteds.some((selected: any) => selected.id === value.depends_on_value_id)
            );
          } else {
            child.values = child.originalValues;
          }
        } else {
          child.values = []; // Garante que child.values tenha um fallback válido
        }
  
        // Recursão para verificar os filhos dos filhos
        this.filterChildValues(child);
      });
    }
  }
}