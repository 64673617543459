import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';
import { ClientsService } from '../../services/user-management/clients.service';
import { UserService } from '../../services/user-management/user.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  currentUser:any
  roles:any
  version: any
  solicitations = false;
  showMenu = true;
  
  @Input() menu: any = []
  @Output() isOpen = new EventEmitter();

  constructor(
    private service: AuthService,
    private clientService: ClientsService,
    private userService: UserService,
    private router: Router
  ) { }









  isSidebarOpen = false;

  menuItems = [
    { name: 'Dashboard', icon: 'bx bx-grid-alt' },
    { name: 'User', icon: 'bx bx-user' },
    { name: 'Message', icon: 'bx bx-chat' },
    { name: 'Analytics', icon: 'bx bx-pie-chart-alt-2' },
    { name: 'File Manager', icon: 'bx bx-folder' },
    { name: 'Order', icon: 'bx bx-cart-alt' },
    { name: 'Settings', icon: 'bx bx-cog' }
  ];

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.isOpen.emit(this.isSidebarOpen);
  }











  ngOnInit(): void {
    this.version = require('../../../../../package.json').version;

    this.currentUser = this.userService.getLocalUser();

    let roles = localStorage.getItem('roles')?.toString()
    if(roles) this.roles = JSON.parse(roles).map((role:any) => role.slug)

    this.solicitations = this.clientService.client_id == '2';

  }

  logOut() {
    Swal.fire({
      icon: 'warning',
      text: `Deseja realmente sair?`,
      showCancelButton: true,
      confirmButtonText: 'Sair',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then((result) => {
      if (result.value) {  
        this.service.logout()
        this.router.navigate(['/login']);
      }
    });
  }
}
