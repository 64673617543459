import { Component, Input, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/shared/services/demands/projects.service';
import { AreaService } from 'src/app/shared/services/user-management/area.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {

  //data
  currentUser: any = null;
  areas: any = null
  projects = {data:[],last_page:0,current_page:0};
  
  //config
  @Input() title = 'Lista de Projetos';
  pagination = [];
  skeleton = true;
  showFiltersBox = false;
  
  //filters
  order = 'desc'
  limit = 0;
  area_id: any = '';
  query = '';
  created_at = '';
  view = 'list'

  constructor(
    private userService: UserService,
    private areaService: AreaService,
    private projectsService: ProjectsService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    this.area_id = this.currentUser.area_id;
    console.log(this.area_id);
    this.getProjects();
    this.getAreas();
  }

  getProjects() { 
    this.skeleton = true;
    this.projectsService.getAll({
      order: this.order,
      limit: this.limit,
      area_id: this.area_id,
      created_at: this.created_at,
      query: this.query
    }).subscribe({
      next: (result: any) => {
        this.projects = result;
        this.skeleton = false;
      },
      error: err => console.log(err)
    })
  }

  newProject() { 

  }

  getAreas() { 
    this.areaService.getAll().subscribe({
      next: (result) => this.areas = result,
      error: (err) => console.log(err)
    })
  }

}
