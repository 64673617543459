import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TimesheetService } from '../../services/demands/timesheet.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DemandsService } from '../../services/demands/demands.service';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit {

  timesheetForm: any;
  start: any;
  timer:any;
  timesheet: any;
  minutes:any;
  
  saved: boolean = false;
  loading: boolean = false;
  show: boolean = false;
  stopped: boolean = false;

  timerSubscription?: Subscription;

  constructor(
    private timesheetService: TimesheetService, 
    private demandService: DemandsService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {

    this.timesheetForm = this.formBuilder.group({
      "id"   :    ['', Validators.required],
      "title":    ['', Validators.required],
    });

    this.timesheetService.getUserActiveTimesheet().subscribe({
      next: (result: any) => { 
        if(result) {
          console.log("tem timesheet ativo")
          console.log(result);
          this.timesheet = result;
          if (this.timesheet) this.startTimer(new Date(this.timesheet.start.replace(' ', 'T')))
        } else 
        localStorage.removeItem('timesheet');
      },
      error: (err) => console.log(err)
    });

    this.timesheetService.startTime$.subscribe((demandTaskId) => { //aqui o código é preparado, no ngOnInit, para executar assim que startTime.next() for invocado.
      if (demandTaskId && !this.timesheet) {
        this.loading = true;
        this.startNewTimesheet(demandTaskId);
      }
    });
  }

  private startNewTimesheet(demandTaskId:number) {
    console.log("iniciando timesheet.....")
    this.timesheetService.start(demandTaskId).subscribe((result: any) => {
      if (result) {
        this.timesheet = result;
        localStorage.setItem('timesheet', JSON.stringify(this.timesheet));
        this.startTimer(new Date(result.start.replace(' ', 'T')));
      }
    }, console.error);
  }

  private startTimer(start: any) {
    this.start = start;
    this.timerSubscription = interval(1000).subscribe(() => this.timer = this.calculateDifference());
    this.loading = false;
  }

  private calculateDifference() {
    const elapsedTime = Math.floor((new Date().getTime() - this.start.getTime()) / 1000);
    const formattedTime = new Date(elapsedTime * 1000).toISOString().substr(11, 8);
    this.minutes = elapsedTime / 60;
    return formattedTime;
  }

  stop() {
    this.timerSubscription?.unsubscribe();
    this.stopped = true;
    this.timer = new Date(Math.ceil(this.minutes) * 60 * 1000).toISOString().substr(11, 8)
    let minutes = Math.ceil(this.minutes);
    
    this.timesheetService.stop({duration: minutes}).subscribe({
      next: (result:any) => { 
        localStorage.removeItem('timesheet');
        this.timesheetForm.patchValue(result);
        this.timesheetService.setEndTime(result.demand_task_id);
        this.saved = true;
        this.timesheet = null;
        this.timer = null;
        this.loading = false;
        this.show = false;
        this.stopped = false;
      },
      error: (err) => console.log(err)
    })
  }

  save() {
    this.timesheetService.saveTitle(this.timesheetForm.value).subscribe({
      next: (result) => { 
        this.saved = true;
        this.timesheet = null;
        this.timesheetForm.reset();
        this.timer = null;
        this.loading = false;
        this.show = false;
        this.stopped = false;
      },
      error: (err) => console.log(err)
    })
  }
}
