<div class="card z-index-2 h-100">
    <div class="card-header pb-0 pt-3 bg-transparent">
        <h6 class="text-capitalize mb-0">{{ title }}</h6>
        <p class="text-xs text-secondary mb-0">% de demandas</p>
    </div>
    <div class="card-body p-3">
        <apx-chart 
            [series]="chartOptions.series" 
            [theme]="chartOptions.theme" 
            [labels]="chartOptions.labels"
            [legend]="chartOptions.legend"
            [noData]="chartOptions.noData"
            [chart]="chartOptions.chart">
        </apx-chart>
    </div>
</div>