<div class="sidebar open" [class.open]="isSidebarOpen">
  <div class="logo_details">
      <img src="assets/argon-dashboard-master/assets/img/claro/logos/aurora_short_logo.svg" class="icon" alt="" [routerLink]="'/'">
      <img src="assets/argon-dashboard-master/assets/img/claro/logos/aurora_logo.svg" alt="" class="logo_name" [routerLink]="'/'">
      <img src="assets/argon-dashboard-master/assets/img/claro/icons/menu_hamburguer.svg" alt="" id="btn" (click)="toggleSidebar()">
  </div>
  <ul class="nav-list">
      <li *ngFor="let m of menu">
          <!-- Verifica se há submenus -->
          <ng-container *ngIf="m.sub && m.sub.length > 0; else singleMenu">
              <!-- Menu com submenus -->
              <a href="#" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + m.name" aria-expanded="false" aria-controls="collapseExample">
                  <span class="link-icon">
                      <img src="assets/argon-dashboard-master/assets/img/claro/icons/sidenav/{{m.icon}}.svg" alt="">
                  </span>
                  <span class="link_name">{{ m.name }}</span>
                  <i class="ni ni-bold-down ms-auto"></i> <!-- Ícone para indicar o colapso -->
              </a>
              <div class="collapse" [id]="'collapse' + m.name">
                  <ul class="submenu">
                      <li *ngFor="let sub of m.sub">
                          <a [routerLink]="sub.routerLink" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                              <span class="link-icon">
                                  <img src="assets/argon-dashboard-master/assets/img/claro/icons/sidenav/{{sub.icon}}.svg" alt="" *ngIf="sub.icon">
                              </span>
                              <span class="link_name">{{ sub.name }}</span>
                          </a>
                      </li>
                  </ul>
              </div>
          </ng-container>
          <!-- Menu sem submenus -->
          <ng-template #singleMenu>
              <a [routerLink]="m.routerLink" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                  <span class="link-icon">
                      <img src="assets/argon-dashboard-master/assets/img/claro/icons/sidenav/{{m.icon}}.svg" alt="">
                  </span>
                  <span class="link_name">{{ m.name }}</span>
              </a>
          </ng-template>
          <span class="tooltip">{{ m.name }}</span>
      </li>
      <li id="logout">
          <a (click)="logOut()">
              <i class="ni ni-button-power text-danger text-sm opacity-10"></i>
              <span class="link_name">Sair</span>
          </a>
          <span class="tooltip">Sair</span>
      </li>
  </ul>
</div>
