<ngx-skeleton-loader 
*ngIf="skeleton"
count="5" 
appearance="line" 
[theme]="{height: '45px'}">
</ngx-skeleton-loader>

<div class="card z-index-2 h-100" *ngIf="!skeleton">
  <div class="card-header py-3 bg-transparent table-filter d-flex align-items-center">
      <h6 class="table-title mb-0">{{ title }} ({{ (demands?.data || demandsTasks?.data).length }})</h6>
      <button class="btn btn-primary ms-3 mb-0 border-radius-4 add-new" (click)="newDemand(create_demand)" *ngIf="addNew">+ Nova Demanda</button>

      <div class="filters ms-auto">
        <button class="btn-filters btn-filters-square me-2 border-radius-8" (click)="showFiltersBox = !showFiltersBox" [class.show]="showFiltersBox"><img src="assets/argon-dashboard-master/assets/img/claro/icons/filters.svg" alt=""></button>
        <div class="box-filters" *ngIf="showFiltersBox">
          <div class="form-group d-flex align-items-center" *ngIf="['leadership', 'screening'].includes(currentUser.group.name)">
            <label class="me-2 text-white text-end flex-grow-1 mb-0">Ordenar por</label>
            <select class="form-control w-60" [(ngModel)]="order" (change)="getItems()">
              <option value="desc">Mais recentes</option>
              <option value="asc">Mais antigas</option>
            </select>
          </div>

          <div class="form-group d-flex align-items-center" >
              <label class="me-2 text-white text-end flex-grow-1 mb-0">Área</label>
              <select name="" id="" class="form-control w-60" #area [(ngModel)]="area_id" (change)="getItems()">
                <option value="0">Selecione</option>
                <option [value]="area.id" *ngFor="let area of areas">{{ area.name }}</option>
              </select>
            </div>

          <div class="form-group d-flex align-items-center mb-0">
            <label class="me-2 text-white text-end flex-grow-1 mb-0">Data</label>
            <input type="date" class="form-control w-60" placeholder="Filtre por data" [(ngModel)]="deadline" (change)="getItems()">
          </div>
        </div>
      </div>
      
      <div class="input-group search w-auto">
        <input type="text"  class="form-control" placeholder="Pesquisar..." [(ngModel)]="query" (keyup.enter)="getItems()" #search>
        <button class="btn btn-search p-2 m-0 shadow-none" type="button"><i class="fas fa-search fa-sm"></i></button>
      </div>
  </div>
  <hr class="m-0 bg-secondary w-95 mx-auto">
  <div class="card-body p-3 pb-4">  
    <div class="table-responsive p-0" *ngIf="demands">
        <table class="table align-items-center mb-0" *ngIf="demands?.data?.length > 0 && context === 'demand'">
          <thead>
            <tr>
              <th class="text-center w-60">Demanda</th>
              <th class="text-center">Prazo</th>
              <th class="text-center">Tarefas</th>
              <!-- <th></th> -->
              <th class="text-center" *ngIf="!['requester'].includes(currentUser.group.name)">Envolvidos</th>
              <th class="text-center">Status da demanda</th>
              <!-- <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of demands.data" class="pointer">
              <td>
                <div class="demand d-flex align-items-center px-2 py-1">
                  <span class="emoji me-2" *ngIf="d.emoji_id && !['requester'].includes(currentUser.group.name)">{{getEmoji(d.emoji_id)}}</span>
                  <h6 class="mb-0 text-sm py-2 pointer demand-name" (click)="showDemand(d.id, content)">
                    <p class="title">
                      <span class="project-code me-2" *ngIf="d.project?.code && d?.item_id">{{ d.project?.code }}.{{ d?.item_id }}</span>
                      <span class="demand-name">{{ d.name }}</span>
                      <span class="project-name d-block" *ngIf="d.project?.name">{{d?.project?.name}}</span>
                    </p>
                  </h6>
                </div>
              </td>
              <td class="align-middle text-center">
                <span>{{ d.deadline | date: "dd/MM/yyyy" }}</span>
              </td>
              <td class="align-middle text-center">
                <span>
                  <img src="assets/argon-dashboard-master/assets/img/claro/icons/tasks_list.svg" alt="" width="20" class="me-2">
                  {{d.tasks_count}}
                </span>
              </td>
              <td class="align-middle text-center text-sm" *ngIf="!['requester'].includes(currentUser.group.name)">
                <div class="avatar-group ms-auto">
                  <div class="avatar avatar-lg avatar-sm rounded-circle" *ngFor="let u of d.involved_users">
                    <img [alt]="u?.name" [title]="u?.name" [ngbTooltip]="u?.name" [src]="u?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'">
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="badge p-0 bg-white">
                  <span class="badge badge-status" 
                  [ngStyle]="{
                    'background-color': addOpacity(d.status.color, 0.1),
                    'color': d.status.color
                  }">
                    {{ d.status.name }}
                  </span>
                </div>
              </td>
              <td class="align-middle text-center d-none">
                <button class="btn btn-link mb-0">
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center d-block py-5" *ngIf="demands?.data?.length == 0 && context === 'task'">
          <small>{{ emptyTableText }}</small>
          <div class="d-flex justify-content-center">
            <ng-lottie [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
          </div>
        </div>
    </div>
    <div class="table-responsive p-0" *ngIf="demandsTasks">
        <table class="table align-items-center mb-0" *ngIf="demandsTasks?.data?.length > 0 && context === 'task'">
          <thead>
            <tr>
              <th class="text-center">Demanda</th>
              <th class="text-center">Tarefa</th>
              <th class="text-center">Prazo</th>
              <th class="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let demandTask of demandsTasks.data" class="pointer">
              <td>
                <div class="demand d-flex align-items-center px-2 py-1">
                  <span class="emoji me-2" *ngIf="demandTask.demand.emoji_id">{{getEmoji(demandTask.demand.emoji_id)}}</span>
                  <h6 class="mb-0 text-sm py-2 pointer demand-name" (click)="showDemand(demandTask.demand.id, content)">
                    <p class="title text-xs">
                      <span class="project-code me-2" *ngIf="demandTask.demand.project?.code && demandTask.demand?.item_id">{{ demandTask.demand.project?.code }}.{{ demandTask.demand?.item_id }}</span>
                      <span class="demand-name">{{ demandTask.demand.name }}</span>
                      <span class="project-name d-block" *ngIf="demandTask.demand.project?.name">{{demandTask.demand?.project?.name}}</span>
                    </p>
                  </h6>
                </div>
              </td>
              <td class="align-middle text-center">
                <span>{{ demandTask.task.name }}</span>
              </td>
              <td class="align-middle text-center">
                <span [class.text-secondary]="!demandTask.expired" [class.text-danger]="demandTask.expired">{{ demandTask.due_date | date: "dd/MM/yyyy" }}</span>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="badge p-0 bg-white">
                  <span class="badge badge-status" [style.background]="demandTask.status.color">
                    {{ demandTask.status.name }}
                  </span>
                </div>
              </td>
              <td class="align-middle text-center d-none">
                <button class="btn btn-link mb-0">
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center d-block py-5" *ngIf="demands?.data?.length == 0 || demandsTasks?.data?.length == 0">
          <small>{{ emptyTableText }}</small>
          <div class="d-flex justify-content-center">
            <ng-lottie [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
          </div>
        </div>
    </div>
    <nav aria-label="Page navigation example" *ngIf="demands.last_page > 1 && !limit">
        <ul class="pagination justify-content-end mt-4">
            <li class="page-item active" *ngFor="let p of pagination"
                [class.active]="demands.current_page == p">
                <a class="page-link" (click)="goPage(p)" href="javascript:;">{{ p }}</a>
            </li>
        </ul>
    </nav>
  </div>
</div>

<ng-template #content let-modal>
  <app-demands-form #demand [demand_id]="demand_id" (demandHeader)="demandHeader($event)" (cancelModal)="modal.dismiss('Cross click')" (updateCalendar)="getDemands()" [modal]="true"></app-demands-form>
</ng-template>

<ng-template #create_demand let-modal>
  <app-requester-demands-form (close)="modal.dismiss('Cross click')"></app-requester-demands-form>
</ng-template>