import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class MetricsService extends BaseService {

  getDemandFielsdMetrics(filter: any = null) {
    return this.post(`metrics/demands/fields`, filter)
  }

  getDashTree() {
    return this.get(`dashtree`)
  }

  getParts(filter: any = null) {
    return this.post(`metrics/parts`, filter)
  }

  getTotalDemands(filter: any = null) {
    return this.post(`metrics/demands/total`, filter)
  }

  export(filter: any = null) {
    return this.post(`metrics/export`, filter)
  }

}
