import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-box-pie-chart',
  templateUrl: './box-pie-chart.component.html',
  styleUrls: ['./box-pie-chart.component.scss']
})
export class BoxPieChartComponent implements OnInit {

  @Input() data: any = []
  @Input() title = ''
  @Input() skeleton = true
  @Input() options:any = {};

  chartOptions: any

  labels: Array<string> = []
  series: Array<number> = []

  constructor() { }

  ngOnInit(): void {
    this.data.forEach((element: any) => {
      this.labels.push(`${element.item.length > 20 ? element.item.slice(0, 20) + "..." : element.item} (${element.total})`)
      this.series.push(element.total)
    });
    
    this.chartOptions = {
      series: this.series,
      labels: this.labels,
      chart: {
        height: 450,
        type: "pie",
        animations: {
          enabled: false
        },
      },
      noData: {
        text: "Nenhum dado para exibir",
        align: "center"
      },
      theme: {
        palette: 'palette1' // upto palette10
      },
      colors: this.options.colors || [],
      legend: {
        show: true,
        fontSize: '12px',
        itemMargin: {
          horizontal: 0,
          vertical: 5
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

}
