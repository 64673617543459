<div class="row mb-4 home-dashboard">
    <div class="col-xl-7">
        <div class="card table-agendas pb-0 h-100">
            <div class="card-body">
                <div class="numbers">
                    <div class="d-flex justify-content-between">
                        <p class="mb-0 text-uppercase font-weight-bold dashboard-card-title">Agenda de atividades</p>
                        <button type="button" class="look-all" routerLink="/analyst/events" >Ver todas</button>
                    </div>
                    <div class="table-responsive" *ngIf="events">
                        <ul id="event-list" class="list-unstyled mt-3">
                            <li *ngFor="let e of events" class="border-bottom px-3">
                                <div class="demand d-flex align-items-center">
                                    <h6 class="mb-0 text-sm py-2 pointer demand-name">
                                      <p class="title text-xs">
                                        <span class="project-name mb-1 d-block" *ngIf="e.demand?.project.name">{{e.demand?.project.name}}</span>
                                        <span class="project-code me-2" *ngIf="e.demand?.project.code && e.demand.item_id">{{ e.demand?.project.code }}.{{ e.demand?.item_id }}</span>
                                        <span class="demand-name">{{ e.demand.name }}</span>
                                      </p>
                                    </h6>
                                    <span class="ms-auto me-2 event-name">{{e.name}}</span>
                                    <span class="btn-tools" role="button" (click)="showDemand(e.demand.id, content)"><i class="fa-solid fa-eye"></i></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- <ngx-skeleton-loader 
                        count="2" 
                        appearance="line" 
                        [theme]="{height: '51px'}"
                        *ngIf="!events">
                    </ngx-skeleton-loader> -->
                    
                    <ng-template #content let-modal>
                        <app-demands-form #demand [demand_id]="demand_id" (cancelModal)="modal.dismiss('Cross click')" [modal]="true"></app-demands-form>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    
    <div class="col-3">
        <div class="card table-tarefas h-100">
            <div class="card-body">
                <div class="numbers">
                    <p class="mb-0 text-uppercase font-weight-bold dashboard-card-title">DEMANDAS E TAREFAS</p>
                    <div class="d-flex align-items-center mt-4">
                        <div class="item flag-count">
                            <div class="count today">{{tasks?.today}}</div>
                            <!-- <ngx-skeleton-loader 
                            count="1" 
                            appearance="line" 
                            [theme]="{height: '50px', width: '50px', borderRadius: '8px', marginBottom: '0px'}"
                            *ngIf="tasks?.today == null">
                            </ngx-skeleton-loader> -->
                            <span>HOJE</span>
                        </div>
                        <div class="item flag-count ms-3">
                            <div class="count all">{{tasks?.all}}</div>
                            <!-- <ngx-skeleton-loader 
                            count="1" 
                            appearance="line" 
                            [theme]="{height: '50px', width: '50px', borderRadius: '8px', marginBottom: '0px'}"
                            *ngIf="tasks?.all == null">
                            </ngx-skeleton-loader> -->
                            <span>TOTAL</span>
                        </div>
                        <small class="ms-3 mb-2">Você é responsável por essas demandas e tarefas.</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <div class="card table-timesheet h-100">
            <div class="card-body">
                <div class="numbers pb-3">
                    <p class="mb-0 text-uppercase font-weight-bold dashboard-card-title">TIMESHEET</p>
                    <div class="d-flex align-items-center mt-4">
                        <h2 class="font-weight-bolder mt-2 mb-0 text-start">
                            {{timesheet_sum}}
                        </h2>
                        <p class="mb-0 text-sm ms-3">
                            horas<br> 
                            apontadas hoje!
                        </p>
                    </div>
                </div>
                <!-- <ngx-skeleton-loader 
                count="1" 
                appearance="line" 
                [theme]="{height: '100px', width: '50px', borderRadius: '8px'}"
                *ngIf="!timesheet_sum">
                </ngx-skeleton-loader> -->
            </div>
        </div>
    </div>
</div>