import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MetricsService } from 'src/app/shared/services/data/metrics.service';
import { StatusService } from 'src/app/shared/services/demands/status.service';
import { AreaService } from 'src/app/shared/services/user-management/area.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
import { BoxStatusOverviewComponent } from 'src/app/shared/components/box-status-overview/box-status-overview.component';
import { ReportsService } from 'src/app/shared/services/data/reports.service';
import saveAs from 'file-saver';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

   @ViewChild('boxStatusOverview') private boxStatusOverview!: BoxStatusOverviewComponent;
  
  skeleton = true
  status = ''
  users: any = []
  statusList: any = []
  manager: any = ''
  data: any
  parts: any
  start = ''
  deadline = ''

  chartOptions: any = null
  chartPartsOptions: any = null

  total: any = null;

  qtdDemands = 0
  qtdParts   = 0

  exportLoading = false

  areas:any;
  area_id:any = ''

  currentUser:any = null;

  constructor(
    private metricsService: MetricsService,
    private userService: UserService,
    private statusService: StatusService,
    private areaService: AreaService,
    private reportsService: ReportsService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    this.area_id = this.currentUser.area_id;
    this.getDemandFielsdMetrics()
    this.getAreas();
    this.getTotal()
    // this.getUsers()
    // this.getStatus()
    // this.getStatusMetrics()
    // this.getParts()
  }

  getTotal() {
    this.metricsService.getTotalDemands(
      {
        start:   this.start,
        deadline:     this.deadline,
        status:  this.status,
        user_id: this.manager,
        area_id: this.area_id
      }
    ).subscribe({
      next: (result: any) => {
        this.total = result

        this.qtdDemands = 0 

       let countDemands: any = setInterval(() => {
        if (result.qtdDemands > 0) this.qtdDemands++
        if(this.qtdDemands == result.qtdDemands) {
          clearInterval(countDemands)
        }
       }, 5)
      },
      error: (err: any) => console.log(err)
    })
  }

  getDemandFielsdMetrics() {
    this.skeleton = true
    this.metricsService.getDemandFielsdMetrics({
      status: this.status,
      start: this.start,
      deadline: this.deadline,
      user_id: this.manager,
      area_id: this.area_id,
      model_type: 'demand',
    }).subscribe({
      next: (result: any) => {
        console.log(result);
        this.data = result
        this.skeleton = false
      },
      error: (err: any) => console.log(err)
    })
  }

  getAreas() {
    this.areaService.getAll().subscribe({
      next: (result)  => this.areas = result,
      error: (err)    => console.log(err)
    })
  }

  // getStatus() {
  //   this.statusService.getAll().subscribe({
  //     next: (result: any) => {
  //       this.statusList = result.data
  //     },
  //     error: (err) => console.log(err)
  //   })
  // }

  getUsers() {
    this.userService.getAll().subscribe({
      next: (result: any) =>  this.users = result.data,
      error: (err) => console.log(err)
    })
  }

  filter() {
    this.total = null;
    this.data = null;
    this.skeleton = true
    console.log(this.area_id);
    this.getDemandFielsdMetrics()
    this.getTotal()
    // this.getStatusMetrics()
    // this.getParts()
  }

  print() {
    window.print()
  }

  onAreaChange(area_id: string): void {
    this.area_id = area_id;
    this.filter(); 
    this.boxStatusOverview.getStatusOverview();
  }

  
  onStartChange(start: string): void {
    this.start = start;
    this.filter();
    this.boxStatusOverview.getStatusOverview();
  }


  onDeadlineChange(deadline: string): void {
    this.deadline = deadline;
    this.filter();
    this.boxStatusOverview.getStatusOverview();
  }

  exportDemands() {
    // Chama o serviço de relatórios para fazer o download do arquivo Excel
    this.reportsService.exportDemands(this.start, this.deadline, this.area_id).subscribe(
      (response: Blob) => {
        // Utiliza o FileSaver.js para forçar o download
        const fileName = 'demandas.xlsx';
        saveAs(response, fileName);
      },
      (error) => {
        console.error('Erro ao baixar o arquivo:', error);
      }
    );
  }

  exportDemandsTasks() {
    // Chama o serviço de relatórios para fazer o download do arquivo Excel
    this.reportsService.exportDemandsTasks(this.start, this.deadline, this.area_id).subscribe(
      (response: Blob) => {
        // Utiliza o FileSaver.js para forçar o download
        const fileName = 'tarefas.xlsx';
        saveAs(response, fileName);
      },
      (error) => {
        console.error('Erro ao baixar o arquivo:', error);
      }
    );
  }

  export() {
    this.exportLoading = true
    this.metricsService.export({}).subscribe({
      next: (result: any) => {
        this.exportLoading = false
        window.open(result.file, 'blank')
      },
      error: (err) => console.log(err)
    })
  }

}
