import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
import Swal from 'sweetalert2';
import { passwordComplexityValidator } from 'src/app/shared/validators/password-complexity.validator';
import { ClientsService } from 'src/app/shared/services/user-management/clients.service';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { FieldService } from 'src/app/shared/services/data/field.service';
import { AreaService } from 'src/app/shared/services/user-management/area.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  registerForm: any;
  pinForm: any;
  loading = false;
  user_hashid: any;
  pin: any = null;
  otpConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    autoblur: true,
    numericInputMode: true,
    classList: {
      container: 'custom-container',
      inputBox: 'custom-input-box',
      input: 'custom-input',
      inputFilled: 'custom-input-filled',
      inputSuccess: 'custom-input-success',
      inputError: 'custom-input-error'
    }
  };

  skeleton = false;
  fields: any = null;
  areas: any = null;

  hidePassword = true;

  // Form data
  formData = new FormData();
  area_id: any;

  constructor(
    private formBuilder: FormBuilder,
    private service: UserService,
    private router: Router,
    private fieldService: FieldService,
    private areaService: AreaService,
    private auth: AuthService,
    private clientsService: ClientsService
  ) { }

  ngOnInit(): void {
    this.clientsService.setClientNavigation("2").subscribe({
      next: (result) => console.log(result),
      error: (err) => console.log(err)
    });

    this.getAreas();
    this.getFields();

    this.registerForm = this.formBuilder.group({
      'name':       ['', Validators.required],
      'email':      ['', Validators.required],
      'phone':      ['', Validators.required],
      // 'area_id':    ['', Validators.required], //requester não tem area
      'fields':     this.formBuilder.array([]),
      'password':   ['', [Validators.required, passwordComplexityValidator()]]
    });

    this.pinForm = this.formBuilder.group({
      'pin': ['', Validators.required]
    });
  }

  setArea (area_id:any) { 
    console.log(area_id);
    this.registerForm.controls['area_id'].setValue(area_id);
  }

  getAreas(): void {
    this.areas = this.areaService.getAll().subscribe({
      next: (result) => {
        console.log(result);
        this.areas = result;
      },
      error: (err) => console.log(err)
    });
  }

  getFields(): void {
    this.skeleton = true;
    this.fieldService.getAll({ hierarchy: true, model_type: 'user' }).subscribe({
      next: (result: any) => {
        this.fields = result.data;
        console.log(this.fields);
        this.skeleton = false;
        if (this.fields) {
          this.fields.forEach((c: any) => c.selecteds = undefined);
        }
        console.log(this.fields);
      },
      error: (err) => console.log(err)
    });
  }

  getInputsValue(): void {
    let selecteds: any[] = [];
    console.log(this.fields);

    const collectSelecteds = (field: any): void => {
      if (field.selecteds) {
        selecteds = selecteds.concat(field.selecteds);  // Achata o array
      }

      if (field.children) {
        field.children.forEach((child: any) => collectSelecteds(child));
      }
    };

    this.fields.forEach((field: any) => collectSelecteds(field));
    
    console.log(selecteds);

    selecteds.forEach(id => {
      this.formData.append("fields[]", id);
    });
  }

  register(data: any): void {
    this.loading = true;
    Object.keys(data.controls).forEach(key => {
      const value = data.get(key)?.value;

      // Verifica se o valor não é nulo ou indefinido
      if (value !== null && value !== undefined) {
        this.formData.append(key, value);
        console.log(this.formData.getAll(key));
      }
    });

    console.log(this.registerForm);
    this.getInputsValue();

    this.service.register(this.formData).subscribe({
      next: (result: any) => {
        console.log(result);
        this.user_hashid = result.hashid;
        this.loading = false;
        this.router.navigateByUrl(`otp/verification/${this.user_hashid}`);
      },
      error: (err) => {
        console.log(err);
        this.loading = false;

        Swal.fire({
          icon: 'warning',
          text: err.error.message,
          confirmButtonText: 'Ok'
        });
      }
    });
  }

  hide() { 
    this.hidePassword = !this.hidePassword;
    console.log('hide', this.hidePassword);
  }
}
