import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import emojis from '../../../../assets/argon-dashboard-master/assets/img/claro/icons/emojis/emojis.json';
import { UserService } from '../../services/user-management/user.service';

@Component({
  selector: 'app-emoji-selector',
  templateUrl: './emoji-selector.component.html',
  styleUrls: ['./emoji-selector.component.scss']
})
export class EmojiSelectorComponent implements OnInit {

  @Input() emoji:any = null
  @Output() emojiSelected = new EventEmitter<number>();
  @Input() positionList = 'top';

  emojis:any;
  show: boolean = false;
  currentUser:any = null
  
  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    this.emojis = emojis;
  }

  toggleEmojiList() {
    this.show = !this.show;
  }

  select(emoji: any) {
    console.log(emoji);
    this.emoji = emoji;
    this.show = false; // Fechar a lista após a seleção
    this.emojiSelected.emit(this.emoji.id);
  }

}
