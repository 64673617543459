<app-box-status-overview 
context="demand" 
[area_id]="area_id" 
[start]="start"
[deadline]="deadline"
#boxStatusOverview>
</app-box-status-overview>
<div class="row">
    <div class="col-12">
        <div class="row align-items-center justify-content-end my-4 py-3" style="background: #4f4b48; border-radius: 14px">
            <div class="col-3 me-auto"><span class="fw-bold text-white fs-5">VISÃO DEPARTAMENTAL</span>
            </div>
            <div class="col-3">
                <select class="form-select" aria-label="form-select-lg" [(ngModel)]="area_id" (change)="filter()">
                    <option value="">Selecione a área</option>
                    <option *ngFor="let a of areas" [value]="a.id">{{ a.name }}</option>
                </select>
            </div>
            <div class="col-2"><input type="date" class="form-control" [(ngModel)]="start" (change)="filter()" [max]="deadline"></div>
            <div class="col-2"><input type="date" class="form-control" [(ngModel)]="deadline" (change)="filter()" [min]="start"></div>
            <div class="col-2">
                <div class="dropdown">
                    <button 
                    class="btn btn-primary dropdown-toggle text-uppercase m-0 w-100 d-flex justify-content-between align-items-center" 
                    type="button" 
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Download
                    </button>
                    <ul class="dropdown-menu w-100">
                    <li><a class="dropdown-item text-uppercase" (click)="exportDemands()">Demandas</a></li>
                    <li><a class="dropdown-item text-uppercase" (click)="exportDemandsTasks()">Tarefas</a></li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-2"><input type="date" class="form-control" [(ngModel)]="start"></div>
            <div class="col-2"><input type="date" class="form-control" [(ngModel)]="end"></div> -->
            <!-- <div class="col-2"><button class="btn btn-success w-100 mb-0" (click)="filter()"><i class="fas fa-filter me-2"></i> Filtar</button></div> -->
            <!-- <div class="col-2">
                <button class="btn btn-dark w-100 mb-0" 
                    (click)="export()">
                        <i class="fas fa-file-excel me-2"></i> 
                        {{ exportLoading ? 'Gerando arquivo...' : 'Exportar' }}
                </button>
            </div> -->
        </div>
    </div>
</div>

<!-- <div class="row align-items-center text-center" *ngIf="total">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Demandas</p>
                    <h5 class="font-weight-bolder">{{ qtdDemands | number }}</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Peças</p>
                    <h5 class="font-weight-bolder">{{ qtdParts | number }}</h5>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="row mt-4">
    <!-- <div class="col-lg-12 mb-4">
        <ngx-skeleton-loader 
            *ngIf="skeleton" 
            count="1" 
            appearance="line"
            [theme]="{height: '450px', marginBottom: '10px', borderRadius: '8px'}">
        </ngx-skeleton-loader>
        <div class="card" *ngIf="!skeleton && chartPartsOptions">
            <div class="card-header pb-0 pt-3 bg-transparent">
                <h6 class="text-capitalize mb-0">Peças</h6>
                <p class="text-xs text-secondary mb-0">Quantidade de peças utilizadas</p>
            </div>
            <div class="card-body p-3" *ngIf="chartPartsOptions != null">
                <apx-chart
                    [series]="chartPartsOptions.series"
                    [chart]="chartPartsOptions.chart"
                    [dataLabels]="chartPartsOptions.dataLabels"
                    [plotOptions]="chartPartsOptions.plotOptions"
                    [theme]="chartPartsOptions.theme" 
                    [noData]="chartPartsOptions.noData"
                    [grid]="chartPartsOptions.grid"
                    [legend]="chartPartsOptions.legend" 
                    [xaxis]="chartPartsOptions.xaxis"
                ></apx-chart>
            </div>
        </div>
    </div> -->
    <div class="col-lg-6 mb-4">
        <ngx-skeleton-loader 
            *ngIf="!total" 
            count="1" 
            appearance="line"
            [theme]="{height: '450px', marginBottom: '10px', borderRadius: '8px'}">
        </ngx-skeleton-loader>
        <app-box-pie-chart 
            [title]="'Total de demandas (' + total.total + ')'" 
            [data]="total.metrics"
            *ngIf="total">
            </app-box-pie-chart>
    </div>
    <ng-container *ngIf="skeleton">
    <div class="col-lg-6 mb-4" *ngFor="let a of [1,2,3,4,5]">
        <ngx-skeleton-loader 
            count="1" 
            appearance="line" 
            [theme]="{height: '450px', marginBottom: '10px', borderRadius: '8px'}">
        </ngx-skeleton-loader>
    </div>
    </ng-container>
    <ng-container *ngFor="let pie of data | orderBy: 'chart_options.order'">
        <div class="col-lg-{{ pie.chart_options?.width || '6' }} mb-4">
            
            <app-box-pie-chart 
                [title]="pie.name" 
                [data]="pie.metrics"
                [options]="pie.chart_options"
                *ngIf="pie.chart_options.chart_type === 'pie'">
            </app-box-pie-chart>
    
            <app-box-bar-chart 
                [title]="pie.name" 
                [data]="pie.metrics"
                [options]="pie.chart_options"
                *ngIf="pie.chart_options.chart_type === 'bar'">
            </app-box-bar-chart>
    
            <app-box-donut-chart 
                [title]="pie.name" 
                [data]="pie.metrics"
                [options]="pie.chart_options"
                *ngIf="pie.chart_options.chart_type === 'donut'">
            </app-box-donut-chart>
    
            <app-box-column-chart 
                [title]="pie.name" 
                [data]="pie.metrics"
                [options]="pie.chart_options"
                *ngIf="pie.chart_options.chart_type === 'column'">
            </app-box-column-chart>
        </div>
    </ng-container>    
</div>