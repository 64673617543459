<div class="container-fluid px-0">
  <!-- Primeira seção: Fields com filhos -->
  <div class="row mb-2">
    <ng-container *ngFor="let field of fields">
      <ng-container *ngIf="field.children?.length > 0 && (!field.area_id || field.area_id === demand.area_id)">
        <div class="col-md-4 col-sm-6 col-12 mb-3">
          <!-- Field principal -->
          <ng-template [ngTemplateOutlet]="fieldTemplate" [ngTemplateOutletContext]="{ field: field }"></ng-template>
        </div>
        <!-- Renderizar filhos na mesma linha -->
        <div class="col-md-4 col-sm-6 col-12 mb-3">
          <ng-container *ngFor="let child of field.children">
            <ng-template [ngTemplateOutlet]="fieldTemplate" [ngTemplateOutletContext]="{ field: child }"></ng-template>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- Segunda seção: Fields sem filhos -->
  <div class="row">
    <ng-container *ngFor="let field of fields">
      <ng-container *ngIf="!field.children?.length && (!field.area_id || field.area_id === demand.area_id)">
        <div class="col-md-4 col-sm-6 col-12 mb-3">
          <ng-template [ngTemplateOutlet]="fieldTemplate" [ngTemplateOutletContext]="{ field: field }"></ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #fieldTemplate let-field="field">
  <div>
    <label class="form-label mb-1">{{ field.name }}</label>
    <ng-multiselect-dropdown
      [placeholder]="'Selecione os itens'"
      [settings]="field.dropdownSettings"
      [disabled]="isDisabled(field)"
      [data]="field.values"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="field.selecteds"
      (onSelect)="onItemSelect(field, $event)"
      (onDeSelect)="onItemDeSelect(field, $event)">
    </ng-multiselect-dropdown>
  </div>
</ng-template>
