import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-box-column-chart',
  templateUrl: './box-column-chart.component.html',
  styleUrls: ['./box-column-chart.component.scss']
})
export class BoxColumnChartComponent {

  @Input() data: any = [];
  @Input() title = '';
  @Input() skeleton = true;
  @Input() options:any = {};

  chartOptions: any;
  labels: Array<string> = [];
  series: Array<number> = [];

  constructor() {}

  ngOnInit(): void {
    if (this.data && this.data.length > 0) {
      this.data.forEach((element: any) => {
        this.labels.push(element.item);
        this.series.push(element.total);
      });
    }

    this.chartOptions = {
      series: [
        {
          name: "Demandas",
          data: this.series
        }
      ],
      noData: {
        text: "Nenhum dado para exibir",
        align: "center"
      },
      chart: {
        height: 450,
        type: "bar", // Tipo de gráfico correto
        toolbar: {
          show: false
        },
        animations: {
          enabled: true
        }
      },
      theme: {
        palette: 'palette1'
      },
      plotOptions: {
        bar: {
          columnWidth: "65%",
          distributed: true,
          horizontal: false
        }
      },
      grid: {
        show: false
      },
      dataLabels: {
        enabled: true
      },
      colors: this.options.colors || [],
      legend: {
        show: false
      },
      xaxis: {
        categories: this.labels, 
      }
    };
  }
}
