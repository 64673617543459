<div class="box-status-container" [ngClass]="{'with-button': showNewDemandButton}">
    
    <!-- Skeleton Loader enquanto carrega -->
    <ngx-skeleton-loader 
    *ngIf="skeleton" 
    count="1" 
    appearance="line"
    [theme]="{height: '93px', marginBottom: '10px', borderRadius: '8px'}">
    </ngx-skeleton-loader>

    <button *ngIf="showNewDemandButton && !skeleton"  class="btn btn-primary mb-0 w-100 new-demand" (click)="newDemand(content)">
        <span class="d-flex">
          <img src="assets/argon-dashboard-master/assets/img/claro/icon-demand-plus.svg" class="me-2" alt="">
          CRIAR UMA<br>DEMANDA
        </span>
    </button>

    <!-- Cards com os status -->
    <div class="card-item" *ngFor="let s of status">
        <div class="card" [ngStyle]="{'border-color': s?.color}">
            <div class="card-body text-center py-3 shadow d-flex justify-content-between align-items-center">
            <div>
                <span class="card-header p-0 m-0 text-uppercase" [ngStyle]="{'color': s?.color}">{{s?.name}}</span>
                <h5 class="m-0" *ngIf="context === 'demand'">{{s.demands_count}}</h5>
                <h5 class="m-0" *ngIf="context === 'task'">{{s.tasks_count}}</h5>
            </div>
            <div class="icon shadow" [ngStyle]="{'background-color': addOpacity(s?.color, 0.1)}">
                <img src="assets/argon-dashboard-master/assets/img/claro/icons/status/{{s?.icon}}" alt="">
            </div>
            </div>
        </div>
    </div>
</div>
  
<ng-template #content let-modal>
    <app-requester-demands-form (close)="modal.dismiss('Cross click')"></app-requester-demands-form>
</ng-template>