<div class="timesheet-card" [ngClass]="show ? 'show' : 'hide'" *ngIf="this.timer">
    <div class="close" role="button" (click)="show = !show"><i class="fa-solid fa-chevron-right"></i></div>
    <div class="form-group bg-white mb-0 p-3">
        <div class="d-flex">
            <span id="timer">{{timer || '00:00:00'}}</span>
            <button class="stop-button" role="button" id="stopButton" (click)="stop()" *ngIf="timer">Parar</button>
        </div>
        <label class="job-title mt-3 mb-0 demand-name">
            <ng-container *ngIf="timesheet.demand_task.demand.project?.code && timesheet.demand_task.demand?.item_id">
                {{timesheet.demand_task.demand.project?.code}}.{{timesheet.demand_task.demand?.item_id}}
            </ng-container> 
            {{' - ' + timesheet.demand_task.demand.name}}
        </label>
    </div>
</div>