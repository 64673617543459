import { Component, OnInit } from '@angular/core';
import { TimesheetService } from '../../services/demands/timesheet.service';
import { TaskService } from '../../services/demands/task.service';
import { ChatService } from '../../services/demands/chat.service';
import { DemandEventsService } from '../../services/demands/demand-events.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard-cards',
  templateUrl: './dashboard-cards.component.html',
  styleUrl: './dashboard-cards.component.scss'
})
export class DashboardCardsComponent implements OnInit {

  data: any 
  skeleton = true
  tasks:any;
  timesheet_sum:any;
  events: any;
  demand_id:any;

  lastMessage: any;

  constructor(
    private timesheetService: TimesheetService,
    private tasksService: TaskService,
    private chatService: ChatService,
    private demandEventsService: DemandEventsService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.getTasksStatusCount();
    this.getTimesheetSum();
    this.getEvents();
  }

  getEvents() { 
    this.demandEventsService.getTodayEvents().subscribe({
      next: (result) => {
        console.log(result)
        this.events = result
      },
      error: (err) => console.log(err)
    })
  }
  
  getTimesheetSum() { 
    this.timesheetService.getUserSumToday().subscribe({
      next: (result) => { 
        console.log(result);
        this.timesheet_sum = this.convertMinutesToHours(Number(result))
      },
      error: (err) => console.log(err)
    });
  }

  getTasksStatusCount () { 
    this.tasksService.getTasksStatusCount().subscribe({
      next: (result) => this.tasks = result,
      error: (err) => console.log(err)
    });
  }

  getLastMessage() {
    this.chatService.getLastMessage().subscribe({
      next: (result) => {
        this.lastMessage = result
      },
      error: (err) => console.log(err)
    })
  }

  convertMinutesToHours(minutes:number) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`;
  }

  showDemand(demand_id: number, content: any) {
    this.demand_id = demand_id
    this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  }

}
